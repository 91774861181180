/******************************************************************************
  Sliders
*******************************************************************************/
.slider {
  //background: #f2f2f2;
  padding: 54px 0;
  
  > .container {
    position: relative;
  }
  .slid {
    position: relative;
    
    .slid-img {
      max-height: 550px;
      z-index: 1;
    }
  }
  .slid-content {
    bottom: 93px;
    color: #fff;
    //font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    padding: 38px 40px 0;
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 3;
    @media (min-width: $media-sm + 1) and (max-width: $media-md) {
      width: 400px;
    }
    
    .content & {
      bottom: 84px;
      padding: 28px 30px 0;
      right: 8px;
      width: 47.333333%;
    }
    .title {
      color: #fff;
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 34px;
      @media (max-width: 480px) {
        max-height: 42px;
        overflow: hidden;
      }
    }
    .descriptions {
      margin: 0 0 37px 0;
    }
    .btn {
      background: #fff;
      color: $general-color;
      font-weight: bold;
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      
      &:hover {
        background: $primary;
        color: #fff;
        -webkit-transition: all .3s linear;
        transition: all .3s linear;
      }
    }
  }
  .slider-nav {
    background-color: $general-color;
    background-color: rgba($general-color,.80);
    bottom: 0;
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 2;
    @media (min-width: $media-sm + 1) and (max-width: $media-md) {
      width: 400px;
    }
    
    .content & {
      right: 0;
      width: 47.333333%;
    }
    .nav-box {
      bottom: 33px;
      left: 23px;
      position: absolute;
      right: 23px;
      text-align: center;
      z-index: 4;
      
      .content & {
        bottom: 30px;
      }
    }
    .prev,
    .next {
      background-color: #fff;
      background-color: rgba(255,255,255,.1);
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      color: #838282;
      display: block;
      height: 60px;
      left: 0;
      line-height: 60px;
      opacity: 0;
      filter: alpha(opacity=0);
      position: absolute;
      text-align: center;
      text-decoration: none;
      visibility: hidden;
      width: 60px;
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      
      .content & {
        height: 36px;
        line-height: 36px;
        width: 36px;
      }
      * {
        vertical-align: middle;
      }
      &:hover {
        background-color: #fff;
        color: #a0a0a0;
        -webkit-transition: all .3s linear;
        transition: all .3s linear;
        
        polygon {
          fill: #9f9f9f;
          -webkit-transition: all .3s linear;
          transition: all .3s linear;
        }
      }
    }
    .next {
      left: auto;
      margin-left: 2px;
      right: 0;
    }
    .prev {
      margin-right: 2px;
    }
    .pagination {
      padding: 0 65px;
      text-align: center;
      
      .content & {
        margin: 5px 0;
      }
    }
  }
  &.load {
    .sliders-box > .col-md-12 + .col-md-12 {
      display: none;
    }
  }
}
.slider .container:hover .slider-nav .prev,
.slider .container:hover .slider-nav .next,
.page-slider:hover .slider-nav .prev,
.page-slider:hover .slider-nav .next {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}
.progressive-slider .slider-nav .pagination a {
  vertical-align: middle;
}
.page-slider {
  background: none;
  padding: 0;
  position: relative;
}
.progressive-slider-two {
  .sliders-container {
    margin: 0 -15px 0 15px;
    padding: 0;
    position: relative;
  }
  .pagination {
    bottom: 0;
    left: 0;
    margin: 0 0 15px;
    position: absolute;
    right: 0;
    text-align: center;
    
    a {
      background: $general-color;
      background: rgba($general-color,.5);
      
      &:hover,
      &.selected {
        background: $general-color;
      }
    }
  }
  .slider-banners {
    display: table;
    height: 100%;
    table-layout: fixed;
    
    .banner {
      background: #f6a232;
      display: table-row;
      height: 33.33333333%;
      
      &:nth-child(1) {
        background: #ee485c;
      }
      &:nth-child(2) {
        background: #65a93c;
      }
    }
  }
  &.load {
    .sliders-box img + img {
      display: none;
    }
  }
}
.progressive-slider-three .slider-wrapper {
  border: solid 6px #fff;
  border-radius: 6px;
}
.progressive-slider-three.load .slider-wrapper img + img,
.progressive-slider-four.load .slider-wrapper img + img {
  display: none;
}
.progressive-slider-four {
  background: none;
  padding: 0;
  position: relative;
  
  .prev,
  .next {
    color: #fff;
    font-size: 40px;
    left: 30px;
    line-height: 1;
    margin-top: -20px;
    position: absolute;
    top: 50%;
  }
  .next {
    left: auto;
    right: 30px;
  }
}
.content .progressive-slider .container {
  padding: 0;
  width: 100%!important;
}
.content .slider.progressive-slider {
  padding: 0;
}

.banner {
  display: block;
  text-align: center;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
  
  &:hover {
    opacity: .8;
    filter: alpha(opacity=80);
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
}