/*
Theme Name: Progressive
Theme URI: http://template.progressive.itembridge.com/
Author: InfoStyle
Author URI: http://themeforest.net/user/InfoStyle
Description: Progressive responsive theme
Version: 3.0.2
License: ThemeForest Regular & Extended License
License URI: http://themeforest.net/licenses/regular_extended
*/

@charset "utf-8";
/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import "../../../node_modules/font-awesome/scss/font-awesome";
// Core variables and mixins
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
// Reset and dependencies
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";
// Core CSS
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";
// Components
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels";
//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges";
//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels";
//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close";
// Components w/ JavaScript
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";
//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel";
// Utility classes
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";
//Variables
@import "theme-variables";
@import "typography";
@import "helper-classes";
@import "top-bar";
@import "header";
@import "breadcrumb";
@import "main";
@import "carousels";
@import "sliders";
@import "sidebar";
//@import "shop";
@import "icons";
//@import "media";
//@import "blog";
@import "footer";
@import "boxed";
//@import "one-page";
//@import "other";
//@import "elements/banner-set";
//@import "elements/blur-pages";
//@import "elements/charts";
//@import "elements/clients";
//@import "elements/contact-us-page";
@import "elements/content-block";
//@import "elements/features";
//@import "elements/frames";
@import "elements/full-width-box";
//@import "elements/galleries";
//@import "elements/latest-posts";
@import "elements/login-page";
//@import "elements/manufactures";
//@import "elements/news";
//@import "elements/packages";
@import "elements/page-404";
//@import "elements/pricings";
//@import "elements/respond";
//@import "elements/search-result";
@import "elements/services";
//@import "elements/sitemap";
//@import "elements/social-feed";
//@import "elements/steps";
//@import "elements/team";
//@import "elements/timeline";
//@import "elements/why-choose";
//@import "elements/word-rotate";
//@import "elements/works";
@import "responsive";

//a:hover {
//  color: $primary;
//}

//.logo-img {
//  height: 60px;
//}

//h1,h2,h3,h4,h5,h6 {
//  font-weight: 800;
//}

h5 {
  font-size: 20px;
}