/******************************************************************************
  404
*******************************************************************************/
.box-404 {
  -webkit-border-radius: 177px;
  -moz-border-radius: 177px;
  border-radius: 177px;
  font-weight: normal;
  height: 354px;
  margin: 0 auto;
  padding: 92px 0 0;
  text-align: center;
  width: 354px;
  
  h1 {
    font-size: 150px;
    font-weight: normal;
    line-height: 0.9;
    margin: 0;
  }
  h2 {
    color: #7f7f7f;
    font-size: 18px;
    font-weight: normal;
    line-height: 1;
    margin: 0;
  }
}
.page-404-promo {
  .page-box {
    background: #2b8cd6;
    background: rgba(43,140,214,.9);
    display: table;
    height: 100% !important;
    width: 100%;
  }
  #main {
    display: table-cell;
    padding: 20px 0;
    vertical-align: middle;
    
    .title {
      border-bottom: 1px solid #fff;
      border-bottom: 1px solid rgba(255,255,255,.3);
      color: #fff;
      padding: 0 10px;
      text-align: center;
      
      h1 {
        display: inline-block;
        font-size: 200px;
        font-weight: normal;
        line-height: 1;
        margin: 0;
        position: relative;
        
        &:before {
          background: #fff;
          bottom: 0;
          content: "";
          display: block;
          height: 1px;
          left: 0;
          position: absolute;
          right: 0;
        }
      }
    }
    .content {
      color: #fff;
      padding: 35px 10px 0;
      text-align: center;
      
      h2 {
        font-size: 24px;
        font-weight: normal;
        margin-bottom: 38px;
      }
      .back-home {
        color: #2b8cd6;
        font-size: 18px;
        padding-right: 30px;
        padding-left: 20px;
        
        .fa {
          font-size: 22px;
          margin-right: 8px;
        }
      }
    }
  }
}