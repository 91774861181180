/******************************************************************************
  Login Page
*******************************************************************************/
/* Login Page 1 */
#main.login-register {
  overflow: hidden;
  padding-bottom: 40px;
}
.form-box.forgot-form {
  margin-bottom: 0;
}
.info-box {
  margin-bottom: 40px;
  padding: 27px 0 0;
}
.info-box .title,
.form-box .title {
  font-weight: normal;
  margin: 0 0 17px;
}
.info-box .descriptions,
.form-box .descriptions {
  margin: 0 0 26px;
}
.form-box {
  background: #f2f2f2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 0 0 40px 0;
  padding: 27px 30px 25px;
  
  .checkbox {
    font-size: 12px;
    margin: 0px 0 28px;
    
    .new-checkbox {
      margin-top: 0;
    }
  }
  .buttons-box {
    font-size: 11px;
    line-height: 1.5;
    
    .btn {
      margin: 3px 8px 5px 0;
    }
    .required {
      font-size: 11px;
      margin: 3px 8px 5px 0;
    }
    .forgot {
      display: inline-block;
      margin: 3px 8px 5px 0;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
      
      &:hover {
        text-decoration: none;
        opacity: 0.8;
        filter: alpha(opacity=80);
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
      }
    }
  }
  .form-group {
    position: relative;
  }
  .help-block {
    bottom: -20px;
    margin: 0;
    position: absolute;
  }
}