/******************************************************************************
  Breadcrumb
*******************************************************************************/
.breadcrumb-box {
  background: #f2f2f2;
  overflow: hidden;
}
.breadcrumb {
  background: #f2f2f2;
  color: #444;
  font-size: 11px;
  line-height: 18px;
  margin: 0;
  padding: 11px 0;

  > li {
    line-height: 18px;
    text-shadow: none;

    > a {
      color: #444;
      text-decoration: none;
      transition: opacity .2s linear;
      
      &:hover {
        opacity: .8;
        filter: alpha(opacity=80);
        transition: opacity .2s linear;
      }
    }
    + li:before {
      padding: 0 6px;
      color: #444;
    }
  }
}
.breadcrumb-none {
  height: 10px;
}
