/******************************************************************************
  Full Width Box
*******************************************************************************/
.full-width-box {
  overflow: hidden;
  padding: 26px 0;
  position: relative;
  transition: opacity .2s linear;
  @media (max-width: $media-xs) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .auto-width {
    margin-left: -15px;
    margin-right: -15px;
    opacity: 0;
    
    &.loaded {
      margin-left: 0;
      margin-right: 0;
      opacity: 1;
      transition: opacity .2s linear;
    }
  }
  //.container & {
  //  margin-left: -15px;
  //  margin-right: -15px;
  //}
  .content-box {
    padding: 50px;
  }
  .images-box {
    padding: 100px;
  }
  .fwb-bg {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 0;
    
    .overlay {
      background: #000;
      height: 100%;
      opacity: .2;
      filter: alpha(opacity=20);
      width: 100%;
    }
  }
  @media (max-width: $media-xs) {
    .container {
      margin-left: 0;
      margin-right: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .container,
  .page-header {
    position: relative;
  }
  .fwb-fixed,
  .fwb-paralax {
    background-attachment: fixed;
  }
  .touch-device & .fwb-paralax,
  .touch-device & .fwb-fixed {
    background-attachment: scroll;
    background-position: 50% 50% !important;
  }
  .fwb-blur {
    background-position: center top !important;
    
    .blur-box {
      bottom: 0;
      display: block !important;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -100;
      
      canvas {
        background-size: cover;
        background-attachment: fixed;
        max-width: inherit;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        vertical-align: top;
      }
    }
  }
  .fwb-video {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    
    video,
    iframe,
    img {
      background-attachment: fixed;
      vertical-align: top;
      position: absolute;
      right: 0;
      bottom: 0;
      max-width: inherit;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0;
    }
  }
}